



























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import api from "@/api";
import { getRouter } from "../../router";

@Component
export default class ResetPassword extends Vue {
  password = "";
  confirmPassword = "";
  passChecks: any = [];
  confirmPassChecks: any = [];
  isPasswordValid = false;
  isConfirmPasswordValid = false;
  isSuccess = true;

  resetPassword() {
    api
      .resetPassword(getRouter().currentRoute.query.code, this.password)
      .then((res) => {
        if (res.status === 200) {
          getRouter().replace("/login");
        } else {
          this.isSuccess = false;
          this.confirmPassChecks.push({
            success: false,
            text:
              "✘ Dieser Link ist nicht mehr gültig. Wenn Sie weiterhin Probleme haben, setzen Sie Ihr Passwort bitte erneut zurück.",
          });
        }
      })
      .catch((err) => {
        this.isSuccess = false;
        this.confirmPassChecks.push({
          success: false,
          text:
            "✘ Fehler! Leider konnten wir Ihr Passwort nicht zurücksetzen. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.",
        });
      });
  }

  noActionFormSubmit(event: Event) {
    event.preventDefault();
  }

  checkPwd() {
    this.passChecks = [];
    this.confirmPassChecks = [];
    if (/[a-z]/.test(this.password)) {
      this.passChecks.push({ success: true, text: "✓ Kleinbuchstabe" });
      this.isPasswordValid = true;
    } else {
      this.passChecks.push({ success: false, text: "✘ Kleinbuchstabe" });
      this.isPasswordValid = false;
    }
    if (/[A-Z]/.test(this.password)) {
      this.passChecks.push({ success: true, text: "✓ Großbuchstabe" });
      this.isPasswordValid = true;
    } else {
      this.passChecks.push({ success: false, text: "✘ Großbuchstabe" });
      this.isPasswordValid = false;
    }
    if (/[0-9]/.test(this.password)) {
      this.passChecks.push({ success: true, text: "✓ Zahl" });
      this.isPasswordValid = true;
    } else {
      this.passChecks.push({ success: false, text: "✘ Zahl" });
      this.isPasswordValid = false;
    }
    if (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(this.password)) {
      this.passChecks.push({ success: true, text: "✓ Sonderzeichen" });
      this.isPasswordValid = true;
    } else {
      this.passChecks.push({ success: false, text: "✘ Sonderzeichen" });
      this.isPasswordValid = false;
    }
    if (this.password.length > 7) {
      this.passChecks.push({ success: true, text: "✓ min. 8 Zeichen" });
      this.isPasswordValid = true;
    } else {
      this.passChecks.push({ success: false, text: "✘ min. 8 Zeichen" });
      this.isPasswordValid = false;
    }
    if (this.password === this.confirmPassword) {
      this.confirmPassChecks.push({
        success: true,
        text: "✓ Die Passwörter simmen überein.",
      });
      this.isConfirmPasswordValid = true;
    } else {
      this.confirmPassChecks.push({
        success: false,
        text: "✘ Die Passwörter simmen nicht überein.",
      });
      this.isConfirmPasswordValid = false;
    }
  }

  checkConfirmPwd() {
    this.confirmPassChecks = [];
    if (this.password === this.confirmPassword) {
      this.confirmPassChecks.push({
        success: true,
        text: "✓ Die Passwörter simmen überein.",
      });
      this.isConfirmPasswordValid = true;
    } else {
      this.confirmPassChecks.push({
        success: false,
        text: "✘ Die Passwörter simmen nicht überein.",
      });
      this.isConfirmPasswordValid = false;
    }
  }
}
